import moment from "moment";
import React from "react";
import { Container } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Carpark } from "../model/Carpark";
import { ParkingFee } from "../model/ParkingFee";
import ParkingFeeDescription from "./ParkingFeeDescription";
import ParkingFeeInterval from "./ParkingFeeInterval";

export interface Props {
  carpark: Carpark;
  clickThroughUrl: string;
  onClick: (carpark: Carpark) => void;
}

export default class CarparkTableItem extends React.Component<Props> {
  getLowestParkingFee(): ParkingFee | undefined {
    let lowestParkingFee: ParkingFee | undefined;

    this.props.carpark.fees?.forEach((fee) => {
      if (
        lowestParkingFee === undefined ||
        lowestParkingFee.parkingFee === undefined ||
        lowestParkingFee.chargeInterval === undefined ||
        (fee !== undefined &&
          fee.parkingFee !== undefined &&
          fee.chargeInterval !== undefined &&
          fee.parkingFee / fee.chargeInterval <
            lowestParkingFee.parkingFee / lowestParkingFee.chargeInterval)
      ) {
        lowestParkingFee = fee;
      }
    });

    return lowestParkingFee;
  }

  render() {
    let lowestParkingFee = this.getLowestParkingFee();

    return (
      <tr onClick={() => this.props.onClick(this.props.carpark)}>
        <td>
          <a href={this.props.clickThroughUrl}>{this.props.carpark.nameZh}</a>
        </td>
        <td>
          {lowestParkingFee ? (
            <ParkingFeeDescription fee={lowestParkingFee} />
          ) : (
            "-"
          )}
        </td>
        <td>
          {lowestParkingFee ? (
            <ParkingFeeInterval fee={lowestParkingFee} />
          ) : (
            "-"
          )}
        </td>
        <td>
          {this.props.carpark.lastUpdateDate
            ? moment(this.props.carpark.lastUpdateDate).format("YYYY-MM-DD")
            : "-"}
        </td>
      </tr>
    );
  }
}
