import { throws } from "assert";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Carpark } from "../model/Carpark";

export interface Props {
  value: number;
  minLabel?: string;
  maxLabel?: string;
}

export default class CarparkInfoRatingBar extends React.Component<Props> {
  render() {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {this.props.minLabel && (
          <span style={{ marginRight: "8px", flex: "0 auto" }}>
            {this.props.minLabel}
          </span>
        )}
        <ProgressBar style={{ flex: "1" }}>
          <ProgressBar variant="success" now={(this.props.value * 100) / 5} />
        </ProgressBar>
        {this.props.maxLabel && (
          <span style={{ marginLeft: "8px", flex: "0 auto" }}>
            {this.props.maxLabel}
          </span>
        )}
      </div>
    );
  }
}
