import React from "react";
import { ParkingFee } from "../model/ParkingFee";

export interface Props {
  fee: ParkingFee;
}

export default class ParkingFeeInterval extends React.Component<Props> {
  render() {
    let text = "";
    switch (this.props.fee.chargeInterval) {
      case 30:
        text = "半小時";
        break;
      case 60:
        text = "小時";
        break;
      default:
        text = this.props.fee.chargeInterval + "分鐘";
    }
    return <span>{"$" + this.props.fee.parkingFee + "/" + text}</span>;
  }
}
