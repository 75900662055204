import { Place } from "../model/Place";
import firebase from "../util/FirebaseUtil";

export namespace PlaceDao {
  export const collectionName = "places";

  const dataConverter: firebase.firestore.FirestoreDataConverter<Place> = {
    toFirestore(place: Place): firebase.firestore.DocumentData {
      return Place.toFirestore(place);
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ): Place {
      return Place.fromFirestore(snapshot.id, snapshot.data());
    },
  };

  export function getPlaceByName(
    name: string
  ): Promise<firebase.firestore.QuerySnapshot<Place>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("nameZh", "==", name)
      .get();
  }

  export function getPlace(
    id: string
  ): Promise<firebase.firestore.DocumentSnapshot<Place>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .doc(id)
      .get();
  }

  export function getPlacesByRegion(
    regionId: string
  ): Promise<firebase.firestore.QuerySnapshot<Place>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentRegion.id", "==", regionId)
      .get();
  }

  export function getPlacesByDistrict(
    districtId: string
  ): Promise<firebase.firestore.QuerySnapshot<Place>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentDistrict.id", "==", districtId)
      .get();
  }

  export function subscribePlacesByDistrict(
    districtId: string,
    onSnapshot: (snapshot: firebase.firestore.QuerySnapshot<Place>) => void
  ): () => void {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentDistrict.id", "==", districtId)
      .onSnapshot(onSnapshot);
  }
}
