import { Carpark } from "../model/Carpark";
import { CarparkDao } from "../dao/CarparkDao";
import { CarparkBrief } from "../model/CarparkBrief";
import { ParentLevel } from "../model/ParentLevel";
import { PlaceDao } from "../dao/PlaceDao";
import { DistrictDao } from "../dao/DistrictDao";
import { RegionDao } from "../dao/RegionDao";

export class CarparkService {
  private unsubscribeCarparks: (() => void) | undefined;

  public async getCarpark(id: string): Promise<Carpark | undefined> {
    return (await CarparkDao.getCarpark(id)).data();
  }

  public async getCarparkByName(
    placeName: string,
    carparkName: string
  ): Promise<Carpark | undefined> {
    let snapshot = await CarparkDao.getCarparkByName(placeName, carparkName);
    if (snapshot.empty) {
      return undefined;
    } else {
      return snapshot.docs[0].data();
    }
  }

  public async getCarparksByPlaceName(placeName: string): Promise<Carpark[]> {
    let querySnapshot = await CarparkDao.getCarparksByPlaceName(placeName);
    return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
  }

  public async getCarparksByPlace(placeId: string): Promise<Carpark[]> {
    let querySnapshot = await CarparkDao.getCarparksByPlace(placeId);
    return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
  }

  public subscribeCarparksByPlace(
    placeId: string,
    onChanged: (carparks: Carpark[]) => void
  ) {
    if (this.unsubscribeCarparks === undefined) {
      this.unsubscribeCarparks = CarparkDao.subscribeCarparksByPlace(
        placeId,
        (querySnapshot) => {
          onChanged(
            querySnapshot.docs.map((docSnapshot) => docSnapshot.data())
          );
        }
      );
    } else {
      console.log("multiple subscription is not allowed");
    }
  }

  public unsubscribeCarparksByPlace() {
    if (this.unsubscribeCarparks !== undefined) {
      this.unsubscribeCarparks();
      this.unsubscribeCarparks = undefined;
    } else {
      console.log("no subscription found");
    }
  }
}
