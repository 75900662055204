export class PlaceBrief {
  id: string | undefined;
  nameZh: string;
  nameEn: string;
  carparkCount: number = 0;

  constructor(
    id: string | undefined,
    nameZh: string,
    nameEn: string,
    carparkCount: number
  ) {
    this.id = id;
    this.nameZh = nameZh;
    this.nameEn = nameEn;
    this.carparkCount = carparkCount;
  }

  static fromFirestore(id: string, data: any): PlaceBrief {
    return new PlaceBrief(id, data.nameZh, data.nameEn, data.carparkCount);
  }

  static toFirestore(placeBrief: PlaceBrief): any {
    return {
      id: placeBrief.id,
      nameZh: placeBrief.nameZh,
      nameEn: placeBrief.nameEn,
      carparkCount: placeBrief.carparkCount,
    };
  }
}
