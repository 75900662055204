import { CarparkBrief } from "./CarparkBrief";
import { ParentLevel } from "./ParentLevel";

export class Place {
  id: string | undefined;
  nameZh: string;
  nameEn: string;
  parentRegion: ParentLevel;
  parentDistrict: ParentLevel;
  carparkBriefs: CarparkBrief[] | undefined;
  carparkCount: number = 0;

  constructor(
    id: string | undefined,
    nameZh: string,
    nameEn: string,
    parentRegion: ParentLevel,
    parentDistrict: ParentLevel
  ) {
    this.id = id;
    this.nameZh = nameZh;
    this.nameEn = nameEn;
    this.parentRegion = parentRegion;
    this.parentDistrict = parentDistrict;
  }

  static fromFirestore(id: string, data: any): Place {
    let carparkBriefs: CarparkBrief[] | undefined;

    if (data.carparkBriefs !== undefined && Array.isArray(data.carparkBriefs)) {
      carparkBriefs = [];
      data.carparkBriefs.forEach((brief: any) =>
        carparkBriefs!.push(CarparkBrief.fromFirestore(brief.id, brief))
      );
    }

    let place = new Place(
      id,
      data.nameZh,
      data.nameEn,
      ParentLevel.fromFirestore(data.parentRegion),
      ParentLevel.fromFirestore(data.parentDistrict)
    );
    place.carparkBriefs = carparkBriefs;
    place.carparkCount = data.carparkCount;
    return place;
  }

  static toFirestore(place: Place): any {
    return {
      nameZh: place.nameZh,
      nameEn: place.nameEn,
      parentRegion: ParentLevel.toFirestore(place.parentRegion),
      parentDistrict: ParentLevel.toFirestore(place.parentDistrict),
      carparkBriefs: place.carparkBriefs?.map((brief) =>
        CarparkBrief.toFirestore(brief)
      ),
      carparkCount: place.carparkCount,
    };
  }
}
