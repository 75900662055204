import { DistrictBrief } from "./DistrictBrief";

export class Region {
  id: string | undefined;
  nameZh: string;
  nameEn: string;
  districtBriefs: DistrictBrief[] | undefined;
  carparkCount: number = 0;

  constructor(id: string | undefined, nameZh: string, nameEn: string) {
    this.id = id;
    this.nameZh = nameZh;
    this.nameEn = nameEn;
  }

  static fromFirestore(id: string, data: any): Region {
    let districtBriefs: DistrictBrief[] | undefined;

    if (
      data.districtBriefs !== undefined &&
      Array.isArray(data.districtBriefs)
    ) {
      districtBriefs = [];
      data.districtBriefs.forEach((brief: any) =>
        districtBriefs!.push(DistrictBrief.fromFirestore(brief.id, brief))
      );
    }

    let region = new Region(id, data.nameZh, data.nameEn);
    region.districtBriefs = districtBriefs;
    region.carparkCount = data.carparkCount;
    return region;
  }

  static toFirestore(region: Region): any {
    return {
      nameZh: region.nameZh,
      nameEn: region.nameEn,
      districtBriefs: region.districtBriefs?.map((brief) =>
        DistrictBrief.toFirestore(brief)
      ),
      carparkCount: region.carparkCount,
    };
  }
}
