import { ParentLevel } from "./ParentLevel";
import { ParkingFee } from "./ParkingFee";
import firebase from "firebase";
import { PaymentMethod } from "./PaymentMethod";

export class Carpark {
  id: string | undefined;
  nameZh?: string;
  nameEn?: string;
  parentRegion?: ParentLevel;
  parentDistrict?: ParentLevel;
  parentPlace?: ParentLevel;
  address: string | undefined;
  googlePlaceId: string | undefined;
  latLng: firebase.firestore.GeoPoint | undefined;
  contacts: string[] | undefined;
  paymentMethods: PaymentMethod[] | undefined;
  parkingSpaces: number | undefined;
  electricCharger: boolean | undefined;
  fees: ParkingFee[] | undefined;
  difficulty: number | undefined;
  parkingSpaceSize: number | undefined;
  remarks: string | undefined;
  lastUpdateDate: Date | undefined;

  static fromFirestore(id: string, data: any): Carpark {
    let carpark = new Carpark();

    carpark.id = id;
    carpark.nameZh = data.nameZh;
    carpark.nameEn = data.nameEn;
    carpark.parentRegion = ParentLevel.fromFirestore(data.parentRegion);
    carpark.parentDistrict = ParentLevel.fromFirestore(data.parentDistrict);
    carpark.parentPlace = ParentLevel.fromFirestore(data.parentPlace);
    carpark.lastUpdateDate = data.lastUpdateDate?.toDate();
    carpark.address = data.address;
    carpark.googlePlaceId = data.googlePlaceId;
    carpark.latLng = data.latlng;

    if (data.contacts !== undefined && Array.isArray(data.contacts)) {
      carpark.contacts = data.contacts;
    }

    carpark.paymentMethods = data.paymentMethods;
    carpark.parkingSpaces = data.parkingSpaces;
    carpark.electricCharger = data.electricCharger;

    if (data.fees !== undefined && Array.isArray(data.fees)) {
      carpark.fees = [];
      data.fees.forEach((fee: any) =>
        carpark.fees!.push(ParkingFee.fromFirestore(fee))
      );
    }

    carpark.difficulty = data.difficulty;
    carpark.parkingSpaceSize = data.parkingSpaceSize;
    carpark.remarks = data.remarks;

    return carpark;
  }

  static toFirestore(carpark: Carpark): any {
    return {
      nameZh: carpark.nameZh,
      nameEn: carpark.nameEn,
      parentRegion:
        carpark.parentRegion !== undefined
          ? ParentLevel.toFirestore(carpark.parentRegion)
          : undefined,
      parentDistrict:
        carpark.parentDistrict !== undefined
          ? ParentLevel.toFirestore(carpark.parentDistrict)
          : undefined,
      parentPlace:
        carpark.parentPlace !== undefined
          ? ParentLevel.toFirestore(carpark.parentPlace)
          : undefined,
      address: carpark.address,
      googlePlaceId: carpark.googlePlaceId,
      latlng: carpark.latLng,
      contacts: carpark.contacts,
      paymentMethods: carpark.paymentMethods,
      parkingSpaces: carpark.parkingSpaces,
      electricCharger: carpark.electricCharger,
      fees: carpark.fees?.map((fee) => ParkingFee.toFirestore(fee)),
      difficulty: carpark.difficulty,
      parkingSpaceSize: carpark.parkingSpaceSize,
      remarks: carpark.remarks,
      lastUpdateDate:
        carpark.lastUpdateDate &&
        firebase.firestore.Timestamp.fromDate(carpark.lastUpdateDate),
    };
  }
}
