import { District } from "../model/District";
import firebase from "../util/FirebaseUtil";

export namespace DistrictDao {
  export const collectionName = "districts";

  const dataConverter: firebase.firestore.FirestoreDataConverter<District> = {
    toFirestore(district: District): firebase.firestore.DocumentData {
      return District.toFirestore(district);
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ): District {
      return District.fromFirestore(snapshot.id, snapshot.data());
    },
  };

  export function getDistrict(
    id: string
  ): Promise<firebase.firestore.DocumentSnapshot<District>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .doc(id)
      .get();
  }

  export function getDistrictByRegion(
    regionId: string
  ): Promise<firebase.firestore.QuerySnapshot<District>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentRegion.id", "==", regionId)
      .get();
  }

  export function subscribeDistrictByRegion(
    regionId: string,
    onSnapshot: (snapshot: firebase.firestore.QuerySnapshot<District>) => void
  ): () => void {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentRegion.id", "==", regionId)
      .onSnapshot(onSnapshot);
  }
}
