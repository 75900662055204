import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import logo from "./images/logo.png";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import MainPage from "./page/MainPage";
import CarparkListByPlacePage from "./page/CarparkListByPlacePage";
import CarparkDetailsPage from "./page/CarparkDetailsPage";
import Constants from "./util/Constants";

class App extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={Constants.siteDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={Constants.siteTitle} />
          <meta property="og:description" content={Constants.siteDescription} />
          <meta property="og:site_name" content={Constants.siteName} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={Constants.siteTitle} />
          <meta
            name="twitter:description"
            content={Constants.siteDescription}
          />
          <title>{Constants.siteTitle}</title>
          <link rel="canonical" href={Constants.siteUrl} />
        </Helmet>
        <div className="page-container">
          <div className="content-wrap">
            <Navbar bg="dark" variant="dark">
              <Container>
                <Navbar.Brand href={window.location.origin}>
                  <img
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt=""
                  />
                  {" " + Constants.siteTitle}
                </Navbar.Brand>
              </Container>
            </Navbar>
            <Alert variant="warning" style={{ textAlign: "center" }}>
              CarParkner作者正以一人之力開發此網站，未來的更新會陸續完善網站。
              <br />
              更新 18/12/2021 : 初步開發完成，資料補完進步中。
            </Alert>
            <Router>
              <Switch>
                <Route path="/carpark/:placeName/:carparkName">
                  <CarparkDetailsPage />
                </Route>
                <Route path="/place/:placeName">
                  <CarparkListByPlacePage />
                </Route>
                <Route path="/">
                  <MainPage />
                </Route>
              </Switch>
            </Router>
          </div>
          <div
            className="footer"
            style={{
              background: "#282c34",
              height: "64px",
              textAlign: "center",
              padding: "16px",
              marginTop: "64px",
            }}
          >
            <p style={{ color: "white" }}>
              Copyright © 2021 Lettucech. All rights reserved.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default App;
