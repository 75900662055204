import queryString, { ParsedQuery } from "query-string";
import React, { ReactElement } from "react";
import { Breadcrumb, Table } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import CarparkTableItem from "../component/CarparkTableItem";
import GoogleAds from "../component/GoogleAds";
import PlaceTable, { PlaceTableItem } from "../component/PlaceTable";
import { Carpark } from "../model/Carpark";
import { District } from "../model/District";
import { Place } from "../model/Place";
import { PlaceBrief } from "../model/PlaceBrief";
import { Region } from "../model/Region";
import { CarparkService } from "../service/CarparkService";
import { DistrictService } from "../service/DistrictService";
import { PlaceService } from "../service/PlaceService";
import { RegionService } from "../service/RegionService";
import Constants from "../util/Constants";

export interface Props extends RouteComponentProps<any> {}

export interface State {
  place?: Place;
  carparks?: Carpark[];
}

class CarparkListByPlacePage extends React.Component<Props, State> {
  placeService = new PlaceService();
  carparkService = new CarparkService();

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.placeService
      .getPlaceByName(this.props.match.params.placeName)
      .then((place) => this.setState({ place: place }));
    this.carparkService
      .getCarparksByPlaceName(this.props.match.params.placeName)
      .then((carparks) => this.setState({ carparks: carparks }));
  }

  render() {
    let title =
      this.props.match.params.placeName + "停車場 - " + Constants.siteName;

    let content;

    if (this.state.carparks !== undefined && this.state.carparks.length > 0) {
      content = (
        <Table>
          <thead>
            <tr>
              <th>停車場</th>
              <th colSpan={2}>最低收費</th>
              <th>更新日期</th>
            </tr>
          </thead>
          <tbody>
            {this.state.carparks.map((carpark) => {
              let clickThroughUrl =
                window.location.origin +
                "/carpark/" +
                carpark.parentPlace?.nameZh +
                "/" +
                carpark.nameZh;
              return (
                <CarparkTableItem
                  carpark={carpark}
                  clickThroughUrl={clickThroughUrl}
                  onClick={(carpark: Carpark) => {
                    window.location.href = clickThroughUrl;
                  }}
                />
              );
            })}
          </tbody>
        </Table>
      );
    } else {
      content = <span>抱歉 Carparkner 的資料庫還未覆蓋此地區</span>;
    }

    return (
      <Container fluid="md" style={{ paddingTop: "16px" }}>
        <Helmet>
          <meta
            name="description"
            content={
              this.props.match.params.placeName +
              "停車場 | " +
              Constants.siteDescription
            }
          />
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
          <link
            rel="canonical"
            href={
              Constants.siteUrl + "/place/" + this.props.match.params.placeName
            }
          />
        </Helmet>
        {/* <GoogleAds slot="2201209974" /> */}

        {this.state.place && (
          <Breadcrumb>
            <Breadcrumb.Item href={window.location.origin}>
              主頁
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {this.state.place.parentRegion.nameZh}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{this.state.place.nameZh}</Breadcrumb.Item>
          </Breadcrumb>
        )}

        <h1>{this.props.match.params.placeName + "停車場"}</h1>

        {content}
      </Container>
    );
  }
}

export default withRouter(CarparkListByPlacePage);
