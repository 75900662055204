import { Carpark } from "../model/Carpark";
import firebase from "../util/FirebaseUtil";

export namespace CarparkDao {
  export const collectionName = "carpark";

  const dataConverter: firebase.firestore.FirestoreDataConverter<Carpark> = {
    toFirestore(carpark: Carpark): firebase.firestore.DocumentData {
      return Carpark.toFirestore(carpark);
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ): Carpark {
      return Carpark.fromFirestore(snapshot.id, snapshot.data());
    },
  };

  export function getCarpark(
    id: string
  ): Promise<firebase.firestore.DocumentSnapshot<Carpark>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .doc(id)
      .get();
  }

  export function getCarparks(): Promise<firebase.firestore.QuerySnapshot<Carpark>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .get();
  }

  export function getCarparkByName(
    placeName: string,
    carparkName: string
  ): Promise<firebase.firestore.QuerySnapshot<Carpark>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentPlace.nameZh", "==", placeName)
      .where("nameZh", "==", carparkName)
      .get();
  }

  export function getCarparksByRegion(
    regionId: string
  ): Promise<firebase.firestore.QuerySnapshot<Carpark>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentRegion.id", "==", regionId)
      .get();
  }

  export function getCarparksByDistrict(
    districtId: string
  ): Promise<firebase.firestore.QuerySnapshot<Carpark>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentDistrict.id", "==", districtId)
      .get();
  }

  export function getCarparksByPlaceName(
    name: string
  ): Promise<firebase.firestore.QuerySnapshot<Carpark>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentPlace.nameZh", "==", name)
      .get();
  }

  export function getCarparksByPlace(
    placeId: string
  ): Promise<firebase.firestore.QuerySnapshot<Carpark>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentPlace.id", "==", placeId)
      .get();
  }

  export function subscribeCarparksByPlace(
    placeId: string,
    onSnapshot: (snapshot: firebase.firestore.QuerySnapshot<Carpark>) => void
  ): () => void {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .where("parentPlace.id", "==", placeId)
      .onSnapshot(onSnapshot);
  }
}
