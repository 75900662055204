export class ParentLevel {
  id?: string;
  nameZh?: string;
  nameEn?: string;

  static fromFirestore(data: any): ParentLevel {
    let parentLevel = new ParentLevel();
    parentLevel.id = data.id;
    parentLevel.nameZh = data.nameZh;
    parentLevel.nameEn = data.nameEn;
    return parentLevel;
  }

  static toFirestore(parentLevel: ParentLevel): any {
    return {
      id: parentLevel.id,
      nameZh: parentLevel.nameZh,
      nameEn: parentLevel.nameEn,
    };
  }
}
