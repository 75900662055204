import { ParkingFee } from "./ParkingFee";
import firebase from "firebase";

export class CarparkBrief {
  id: string | undefined;
  nameZh: string;
  nameEn: string;
  lowestFee: ParkingFee | undefined;
  lastUpdateDate: Date;

  constructor(
    id: string | undefined,
    nameZh: string,
    nameEn: string,
    lowestFee: ParkingFee | undefined,
    lastUpdateDate: Date
  ) {
    this.id = id;
    this.nameZh = nameZh;
    this.nameEn = nameEn;
    this.lowestFee = lowestFee;
    this.lastUpdateDate = lastUpdateDate;
  }

  static fromFirestore(id: string, data: any): CarparkBrief {
    return new CarparkBrief(
      id,
      data.nameZh,
      data.nameEn,
      data.lowestFee && ParkingFee.fromFirestore(data.lowestFee),
      data.lastUpdateDate && data.lastUpdateDate.toDate()
    );
  }

  static toFirestore(carparkBrief: CarparkBrief): any {
    return {
      id: carparkBrief.id,
      nameZh: carparkBrief.nameZh,
      nameEn: carparkBrief.nameEn,
      lowestFee: carparkBrief.lowestFee?.toFirestore(),
      lastUpdateDate:
        carparkBrief.lastUpdateDate &&
        firebase.firestore.Timestamp.fromDate(carparkBrief.lastUpdateDate),
    };
  }
}
