import React from "react";
import { Container, Table } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Carpark } from "../model/Carpark";
import { ParkingFee } from "../model/ParkingFee";

export interface Props {
  fee: ParkingFee;
}

export default class ParkingFeeDescription extends React.Component<Props> {
  weekdaysText = ["一", "二", "三", "四", "五", "六", "日"];
  weekdays: boolean[] = [];
  text = "";

  constructor(props: Props) {
    super(props);

    this.weekdays[0] = this.props.fee.monday;
    this.weekdays[1] = this.props.fee.tuesday;
    this.weekdays[2] = this.props.fee.wednesday;
    this.weekdays[3] = this.props.fee.thursday;
    this.weekdays[4] = this.props.fee.friday;
    this.weekdays[5] = this.props.fee.saturday;
    this.weekdays[6] = this.props.fee.sunday;

    let description = "";
    let continousDayDescriptionFirstDay = "";
    let continousDayDescriptionLastDay = "";
    let continousDayStarted = false;
    let continousDayEnded = false;
    let continousDayCount = 0;
    let continousDay = false;
    for (let i = 0; i < this.weekdays.length; i++) {
      if (this.weekdays[i]) {
        if (description.length > 0) {
          description += "、";
        }
        description += this.weekdaysText[i];
      }

      if (!continousDayStarted) {
        if (this.weekdays[i]) {
          continousDayDescriptionFirstDay = this.weekdaysText[i];
          continousDayStarted = true;
          continousDayCount++;
        }
      } else {
        if (this.weekdays[i] && !continousDayEnded) {
          continousDayCount++;
          if (continousDayCount > 2) {
            continousDay = true;
            continousDayDescriptionLastDay = this.weekdaysText[i];
          }
        } else if (this.weekdays[i] && continousDayEnded) {
          continousDay = false;
        } else {
          continousDayEnded = true;
        }
      }
    }

    if (continousDay) {
      this.text = continousDayDescriptionFirstDay + "至" + continousDayDescriptionLastDay;
    } else {
      this.text = description;
    }

    if (this.text.length > 0) {
      this.text = "星期" + this.text;
    }

    if (this.props.fee.publicHoliday) {
      if (this.text.length > 0) {
        this.text += "、";
      }
      this.text += "公眾假期";
    }

    let remarks = "";
    if (
      (this.props.fee.startTime !== undefined &&
        this.props.fee.startTime !== "00:00") ||
      (this.props.fee.endTime !== undefined &&
        this.props.fee.endTime !== "00:00")
    ) {
      remarks += this.props.fee.startTime + " - " + this.props.fee.endTime;
    }

    if (
      this.props.fee.remarks !== undefined &&
      this.props.fee.remarks.length > 0
    ) {
      if (remarks.length > 0) {
        remarks += ", ";
      }
      remarks += this.props.fee.remarks;
    }

    if (remarks.length > 0) {
      this.text += " (" + remarks + ")";
    }
  }

  render() {
    return <span>{this.text}</span>;
  }
}
