import { CarparkDao } from "../dao/CarparkDao";
import { ParentLevel } from "../model/ParentLevel";
import { PlaceDao } from "../dao/PlaceDao";
import { DistrictDao } from "../dao/DistrictDao";
import { RegionDao } from "../dao/RegionDao";
import { DistrictBrief } from "../model/DistrictBrief";
import { District } from "../model/District";

export class DistrictService {
  private unsubscribeDistrict: (() => void) | undefined;

  public async getDistrict(id: string): Promise<District | undefined> {
    return (await DistrictDao.getDistrict(id)).data();
  }

  public async getDistrictByRegion(regionId: string): Promise<District[]> {
    let querySnapshot = await DistrictDao.getDistrictByRegion(regionId);
    return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
  }

  public subscribeDistrictByRegion(
    regionId: string,
    onChanged: (districts: District[]) => void
  ) {
    if (this.unsubscribeDistrict === undefined) {
      this.unsubscribeDistrict = DistrictDao.subscribeDistrictByRegion(
        regionId,
        (querySnapshot) => {
          onChanged(
            querySnapshot.docs.map((docSnapshot) => docSnapshot.data())
          );
        }
      );
    } else {
      console.log("multiple subscription is not allowed");
    }
  }

  public unsubscribeDistrictByRegion() {
    if (this.unsubscribeDistrict !== undefined) {
      this.unsubscribeDistrict();
      this.unsubscribeDistrict = undefined;
    } else {
      console.log("no subscription found");
    }
  }
}
