import { Region } from "../model/Region";
import firebase from "../util/FirebaseUtil";

export namespace RegionDao {
  export const collectionName = "regions";

  const dataConverter: firebase.firestore.FirestoreDataConverter<Region> = {
    toFirestore(region: Region): firebase.firestore.DocumentData {
      return Region.toFirestore(region);
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ): Region {
      return Region.fromFirestore(snapshot.id, snapshot.data());
    },
  };

  export function addRegion(
    region: Region
  ): Promise<firebase.firestore.DocumentReference<Region>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .add(region);
  }

  export function getRegion(
    id: string
  ): Promise<firebase.firestore.DocumentSnapshot<Region>> {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .doc(id)
      .get();
  }

  export function getRegions(): Promise<
    firebase.firestore.QuerySnapshot<Region>
  > {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .get();
  }

  export function subscribeRegions(
    onSnapshot: (snapshot: firebase.firestore.QuerySnapshot<Region>) => void
  ): () => void {
    return firebase
      .firestore()
      .collection(collectionName)
      .withConverter(dataConverter)
      .onSnapshot(onSnapshot);
  }

  export function updateRegion(region: Region): Promise<void> {
    return firebase
      .firestore()
      .collection(collectionName)
      .doc(region.id)
      .withConverter(dataConverter)
      .set(region);
  }

  export function removeRegion(id: string): Promise<void> {
    return firebase.firestore().collection(collectionName).doc(id).delete();
  }
}
