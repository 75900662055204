export class ParkingFee {
  parkingFee?: number;
  chargeInterval?: number;
  descriptionZh?: string;
  descriptionEn?: string;
  monday = false;
  tuesday = false;
  wednesday = false;
  thursday = false;
  friday = false;
  saturday = false;
  sunday = false;
  publicHoliday = false;
  startTime?: string;
  endTime?: string;
  remarks?: string;

  static fromFirestore(data: any): ParkingFee {
    let parkingFee = new ParkingFee();

    parkingFee.parkingFee = data.parkingFee;
    parkingFee.chargeInterval = data.chargeInterval;
    parkingFee.descriptionZh = data.descriptionZh;
    parkingFee.descriptionEn = data.descriptionEn;

    parkingFee.monday = data.monday;
    parkingFee.tuesday = data.tuesday;
    parkingFee.wednesday = data.wednesday;
    parkingFee.thursday = data.thursday;
    parkingFee.friday = data.friday;
    parkingFee.saturday = data.saturday;
    parkingFee.sunday = data.sunday;
    parkingFee.publicHoliday = data.publicHoliday;

    parkingFee.startTime = data.startTime;
    parkingFee.endTime = data.endTime;

    parkingFee.remarks = data.remarks;

    return parkingFee;
  }

  static toFirestore(parkingFee: ParkingFee) {
    return {
      parkingFee: parkingFee.parkingFee,
      chargeInterval: parkingFee.chargeInterval,
      descriptionZh: parkingFee.descriptionZh,
      descriptionEn: parkingFee.descriptionEn,
      monday: parkingFee.monday,
      tuesday: parkingFee.tuesday,
      wednesday: parkingFee.wednesday,
      thursday: parkingFee.thursday,
      friday: parkingFee.friday,
      saturday: parkingFee.saturday,
      sunday: parkingFee.sunday,
      publicHoliday: parkingFee.publicHoliday,
      startTime: parkingFee.startTime,
      endTime: parkingFee.endTime,
      remarks: parkingFee.remarks
    };
  }

  toFirestore() {
    return ParkingFee.toFirestore(this);
  }
}
