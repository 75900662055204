import React from "react";
import { Container, Table } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Carpark } from "../model/Carpark";
import { ParkingFee } from "../model/ParkingFee";
import ParkingFeeDescription from "./ParkingFeeDescription";
import ParkingFeeInterval from "./ParkingFeeInterval";

export interface Props {
  parkingFees: ParkingFee[];
}

export default class ParkingFeesTable extends React.Component<Props> {
  buildTableRow(parkingFee: ParkingFee) {
    return (
      <tr>
        <td>{parkingFee ? <ParkingFeeDescription fee={parkingFee} /> : "-"}</td>
        <td>{parkingFee ? <ParkingFeeInterval fee={parkingFee} /> : "-"}</td>
      </tr>
    );
  }

  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th>停車場</th>
            <th>最低收費</th>
          </tr>
        </thead>
        <tbody>
          {this.props.parkingFees.map((parkingFee) =>
            this.buildTableRow(parkingFee)
          )}
        </tbody>
      </Table>
    );
  }
}
