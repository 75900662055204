import React from "react";
import { Container } from "react-bootstrap";
import Constants from "../util/Constants";

export interface Props {
  title: string;
  items?: PlaceTableItem[];
}

export default class PlaceTable extends React.Component<Props> {
  render() {
    return (
      <div>
        <h2>{this.props.title}</h2>
        <Container fluid="md">
          {this.props.items &&
            this.props.items.map((item) => {
              if (item.carparkCount > 0) {
                return (
                  <a
                    href={window.location.origin + "/place/" + item.label}
                    className="place-table-item"
                  >
                    {item.label + " (" + item.carparkCount + ")"}
                  </a>
                );
              } else {
                return (
                  <a
                    href={window.location.origin + "/place/" + item.label}
                    className="place-table-item"
                    style={{backgroundColor: "lightgray"}}
                  >
                    {item.label + " (" + item.carparkCount + ")"}
                  </a>
                );
              }
            })}
        </Container>
      </div>
    );
  }
}

export class PlaceTableItem {
  id: string;
  label: string;
  carparkCount: number = 0;

  constructor(id: string, label: string, carparkCount: number) {
    this.id = id;
    this.label = label;
    this.carparkCount = carparkCount;
  }
}
