import React from "react";
import { Breadcrumb, Container, Row, Col, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GoogleAds from "../component/GoogleAds";
import CarparkInfoRatingBar from "../component/CarparkInfoRatingBar";
import ParkingFeesTable from "../component/ParkingFeesTable";
import { Carpark } from "../model/Carpark";
import { PaymentMethod } from "../model/PaymentMethod";
import { CarparkService } from "../service/CarparkService";
import Constants from "../util/Constants";

export interface Props extends RouteComponentProps<any> {}

export interface State {
  carpark?: Carpark;
}

class CarparkDetailsPage extends React.Component<Props, State> {
  carparkService = new CarparkService();

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.carparkService
      .getCarparkByName(
        this.props.match.params.placeName,
        this.props.match.params.carparkName
      )
      .then((carpark) => this.setState({ carpark: carpark }));
  }

  render() {
    let title =
      this.props.match.params.carparkName + " - " + Constants.siteName;

    let map;

    if (this.state.carpark?.googlePlaceId !== undefined && this.state.carpark?.googlePlaceId.length > 0) {
      map = (
        <iframe
          title="googleMap"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?key=AIzaSyCki8kFvU2nnDsSxV-CdcBPDKOimz0L1BY&q=place_id:" +
            this.state.carpark.googlePlaceId
          }
        />
      );
    } else if (this.state.carpark?.latLng !== undefined) {
      map = (
        <iframe
          title="googleMap"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={
            "https://www.google.com/maps/embed/v1/place?key=AIzaSyCki8kFvU2nnDsSxV-CdcBPDKOimz0L1BY&q=" +
            this.state.carpark.latLng.latitude +
            "," +
            this.state.carpark.latLng.longitude
          }
        />
      );
    }

    return (
      <Container fluid="md" style={{ paddingTop: "16px" }}>
        <Helmet>
          <meta
            name="description"
            content={
              this.props.match.params.carparkName +
              " | " +
              Constants.siteDescription
            }
          />
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
          <link
            rel="canonical"
            href={
              Constants.siteUrl +
              "/carpark/" +
              this.props.match.params.placeName +
              "/" +
              this.props.match.params.carparkName
            }
          />
        </Helmet>
        {/* <GoogleAds slot="2309190995" /> */}

        {this.state.carpark && (
          <div>
            <Breadcrumb>
              <Breadcrumb.Item href={window.location.origin}>
                主頁
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {this.state.carpark.parentRegion?.nameZh}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                href={
                  window.location.origin +
                  "/place/" +
                  this.state.carpark.parentPlace?.nameZh
                }
              >
                {this.state.carpark.parentPlace?.nameZh}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {this.state.carpark.nameZh}
              </Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col>
                <h1>{this.state.carpark.nameZh}</h1>

                <p>地址: {this.state.carpark.address}</p>
                <p>聯絡方式: {this.state.carpark.contacts?.join(", ")}</p>
                <p>
                  付款方式:{" "}
                  {this.state.carpark.paymentMethods
                    ?.map((paymentMethod) => {
                      switch (paymentMethod) {
                        case PaymentMethod.cash:
                          return "現金";
                        case PaymentMethod.creditCard:
                          return "信用卡";
                        case PaymentMethod.octopus:
                          return "八達通";
                        default:
                          return "";
                      }
                    })
                    .join(", ")}
                </p>
                <p>
                  車位數量:{" "}
                  {this.state.carpark.parkingSpaces !== undefined
                    ? this.state.carpark.parkingSpaces
                    : "無紀錄"}
                </p>
                <p>
                  電車充電: {this.state.carpark.electricCharger ? "有" : "沒有"}
                </p>
                <Table>
                  <thead>
                    <th>出入難度</th>
                    <th>車位大小</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.state.carpark.difficulty && (
                          <CarparkInfoRatingBar
                            value={this.state.carpark.difficulty}
                            minLabel="易"
                            maxLabel="難"
                          />
                        )}
                      </td>
                      <td>
                        {this.state.carpark.parkingSpaceSize && (
                          <CarparkInfoRatingBar
                            value={this.state.carpark.parkingSpaceSize}
                            minLabel="小"
                            maxLabel="大"
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <p>備註: {this.state.carpark.remarks}</p>

                {this.state.carpark.fees && (
                  <ParkingFeesTable parkingFees={this.state.carpark.fees} />
                )}
              </Col>
              <Col>{map}</Col>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}

export default withRouter(CarparkDetailsPage);
