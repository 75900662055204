import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyCki8kFvU2nnDsSxV-CdcBPDKOimz0L1BY",
  authDomain: "carparkner.firebaseapp.com",
  projectId: "carparkner",
  storageBucket: "carparkner.appspot.com",
  messagingSenderId: "672565805534",
  appId: "1:672565805534:web:2a7fcb6bdd6f91a5209d4f",
  measurementId: "G-YG5C37101P",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true });
firebase.firestore().enablePersistence();

export default firebase;
