import { CarparkDao } from "../dao/CarparkDao";
import { ParentLevel } from "../model/ParentLevel";
import { PlaceDao } from "../dao/PlaceDao";
import { DistrictDao } from "../dao/DistrictDao";
import { RegionDao } from "../dao/RegionDao";
import { Place } from "../model/Place";
import { District } from "../model/District";
import { PlaceBrief } from "../model/PlaceBrief";

export class PlaceService {
  unsubribePlaces: (() => void) | undefined;

  public async getPlace(id: string): Promise<Place | undefined> {
    return (await PlaceDao.getPlace(id)).data();
  }

  public async getPlaceByName(name: string): Promise<Place | undefined> {
    let snapshot = await PlaceDao.getPlaceByName(name);
    if (snapshot.empty) {
      return undefined;
    } else {
      return snapshot.docs[0].data();
    }
  }

  public async getPlacesByDistrict(districtId: string): Promise<Place[]> {
    let querySnapshot = await PlaceDao.getPlacesByDistrict(districtId);
    return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
  }

  public subscribePlacesByDistrict(
    districtId: string,
    onChanged: (places: Place[]) => void
  ) {
    if (this.unsubribePlaces === undefined) {
      this.unsubribePlaces = PlaceDao.subscribePlacesByDistrict(
        districtId,
        (querySnapshot) => {
          onChanged(
            querySnapshot.docs.map((docSnapshot) => docSnapshot.data())
          );
        }
      );
    } else {
      console.log("multiple subscription is not allowed");
    }
  }

  public unsubribePlacesByDistrict() {
    if (this.unsubribePlaces !== undefined) {
      this.unsubribePlaces();
      this.unsubribePlaces = undefined;
    } else {
      console.log("no subscription found");
    }
  }
}
