import { PlaceBrief } from "./PlaceBrief";
import { ParentLevel } from "./ParentLevel";

export class District {
  id: string | undefined;
  nameZh: string;
  nameEn: string;
  parentRegion: ParentLevel;
  placeBriefs: PlaceBrief[] | undefined;
  carparkCount: number = 0;

  constructor(
    id: string | undefined,
    nameZh: string,
    nameEn: string,
    parentRegion: ParentLevel
  ) {
    this.id = id;
    this.nameZh = nameZh;
    this.nameEn = nameEn;
    this.parentRegion = parentRegion;
  }

  static fromFirestore(id: string, data: any): District {
    let placeBriefs: PlaceBrief[] | undefined;

    if (data.placeBriefs !== undefined && Array.isArray(data.placeBriefs)) {
      placeBriefs = [];
      data.placeBriefs.forEach((brief: any) =>
        placeBriefs!.push(PlaceBrief.fromFirestore(brief.id, brief))
      );
    }

    let district = new District(
      id,
      data.nameZh,
      data.nameEn,
      ParentLevel.fromFirestore(data.parentRegion)
    );
    district.placeBriefs = placeBriefs;
    district.carparkCount = data.carparkCount;
    return district;
  }

  static toFirestore(district: District): any {
    return {
      nameZh: district.nameZh,
      nameEn: district.nameEn,
      parentRegion: ParentLevel.toFirestore(district.parentRegion),
      placeBriefs: district.placeBriefs?.map((brief) =>
        PlaceBrief.toFirestore(brief)
      ),
      carparkCount: district.carparkCount,
    };
  }
}
