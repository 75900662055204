import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GoogleAds from "../component/GoogleAds";
import PlaceTable, { PlaceTableItem } from "../component/PlaceTable";
import { District } from "../model/District";
import { PlaceBrief } from "../model/PlaceBrief";
import { Region } from "../model/Region";
import { DistrictService } from "../service/DistrictService";
import { RegionService } from "../service/RegionService";

export interface Props extends RouteComponentProps<any> {}

export interface State {
  regionList?: Region[];
  districtList?: District[];
}

class MainPage extends React.Component<Props, State> {
  regionService = new RegionService();
  districtService = new DistrictService();

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchPlaces();
  }

  async fetchPlaces() {
    let regionList = await this.regionService.getRegions();
    let districtList: District[] = [];

    for (let i = 0; i < regionList.length; i++) {
      districtList = districtList.concat(
        await this.districtService.getDistrictByRegion(regionList[i].id!)
      );
    }

    this.setState({ regionList: regionList, districtList: districtList });
  }

  buildPlaceTable(region: Region, districts: District[]): ReactElement {
    return (
      <>
        <PlaceTable
          title={region.nameZh}
          items={districts
            .filter(
              (district) =>
                district.parentRegion.id === region.id &&
                district.placeBriefs !== undefined
            )
            .flatMap((district) => district.placeBriefs)
            .map(
              (placeBrief) =>
                new PlaceTableItem(placeBrief!.id!, placeBrief!.nameZh, placeBrief!.carparkCount)
            )}
        />
      </>
    );
  }

  render() {
    return (
      <Container fluid="md">
        {/* <GoogleAds slot="5999938853" /> */}
        {this.state.regionList &&
          this.state.regionList.map((region) =>
            this.buildPlaceTable(region, this.state.districtList!)
          )}
      </Container>
    );
  }
}

export default withRouter(MainPage);
