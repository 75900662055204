import { CarparkDao } from "../dao/CarparkDao";
import { DistrictDao } from "../dao/DistrictDao";
import { PlaceDao } from "../dao/PlaceDao";
import { RegionDao } from "../dao/RegionDao";
import { Region } from "../model/Region";

export class RegionService {
  unsubscribeRegions: (() => void) | undefined;

  public async getRegion(id: string): Promise<Region | undefined> {
    return (await RegionDao.getRegion(id)).data();
  }

  public async getRegions(): Promise<Region[]> {
    let querySnapshot = await RegionDao.getRegions();
    return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
  }

  public subscribeRegions(onChanged: (regions: Region[]) => void) {
    if (this.unsubscribeRegions === undefined) {
      this.unsubscribeRegions = RegionDao.subscribeRegions((querySnapshot) => {
        onChanged(querySnapshot.docs.map((docSnapshot) => docSnapshot.data()));
      });
    } else {
      console.log("multiple subscription is not allowed");
    }
  }

  public unsubribeRegions() {
    if (this.unsubscribeRegions !== undefined) {
      this.unsubscribeRegions();
      this.unsubscribeRegions = undefined;
    } else {
      console.log("no subscription found");
    }
  }
}
